<template>
  <div
    :class="{ 'is-loading': loading }"
    class="inspiration-block"
  >
    <h3
      v-if="title"
      class="inspiration-block__title"
    >
      {{ title }}
    </h3>
    <div class="inspiration-block__header">
      <ul class="tags-list tags-list--dark">
        <li
          v-for="(tag, tagUid) in tags"
          :key="tagUid"
          class="tags-list__item"
        >
          <a
            :class="{ active: activeTagUid === tagUid }"
            class="tags-list__link"
            @click.prevent="selectTag(tagUid)"
          >
            {{ tag }}
          </a>
        </li>
      </ul>
    </div>

    <div
      ref="content"
      class="inspiration-block__content"
    >
      <loader :loading="loading" />

      <template v-if="isMobile">
        <div class="new-teaser-grid">
          <item-card
            v-if="inspirationData.recipeBlock"
            :model="inspirationData.recipeBlock"
            :list-name="tracking.listName"
            class="new-teaser-grid__item teaser-grid__item--one-half"
          />

          <item-card
            v-if="inspirationData.productBlock"
            :model="inspirationData.productBlock"
            :list-name="tracking.listName"
            class="new-teaser-grid__item teaser-grid__item--one-half"
          />
        </div>

        <card-slider
          v-if="inspirationData.articleBlocks && inspirationData.articleBlocks.length"
          :items="inspirationData.articleBlocks"
          :link-text="readMore"
          card-type="article"
        />
      </template>

      <div
        v-else
        class="new-teaser-grid"
      >
        <item-card
          v-if="inspirationData.recipeBlock"
          :model="inspirationData.recipeBlock"
          :list-name="tracking.listName"
          class="new-teaser-grid__item teaser-grid__item--one-half"
        />

        <item-card
          v-for="article in inspirationData.articleBlocks"
          :key="article.url"
          :model="article"
          :list-name="tracking.listName"
          class="new-teaser-grid__item teaser-grid__item--one-quarter"
        />

        <item-card
          v-if="inspirationData.productBlock"
          :model="inspirationData.productBlock"
          :list-name="tracking.listName"
          class="new-teaser-grid__item teaser-grid__item--one-half"
        />
      </div>
    </div>

    <div
      v-if="inspirationData.allArticlesLink
        && inspirationData.allArticlesLink.link && inspirationData.allArticlesLink.text"
      class="inspiration-block__footer"
    >
      <a
        :href="inspirationData.allArticlesLink.link"
        class="inspiration-block__see-more-link"
      >
        {{ inspirationData.allArticlesLink.text }}
      </a>
    </div>
  </div>
</template>

<script>
import api from '@/lib/api'
import GtmHelper from '@/helpers/GtmHelper'

import ItemCard from '@/components/itemsOverview/item-card.vue'
import CardSlider from '@/components/card-slider.vue'
import Loader from '@/components/loader.vue'

export default {
  components: {
    ItemCard,
    CardSlider,
    Loader
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    readMore: {
      type: String,
      default: ''
    },
    tags: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      inspirationData: {},
      tracking: {
        listName: 'Inspiration block'
      },
      activeTagUid: localStorage.getItem('inspirationTagUid') || '',
      loading: false,
      contentHeight: '',
      delay: 350
    }
  },
  computed: {
    isMobile() {
      return this.$mq !== 'desktop'
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const tagUid = this.activeTagUid
      this.fetchInspirationData(tagUid)
    },
    fetchInspirationData(tagUid) {
      return api.getInspirationBlockData(tagUid)
        .then(({ data }) => {
          this.inspirationData = data
        })
    },
    selectTag(tagUid) {
      if (this.loading) {
        return
      }

      GtmHelper.changeInspirationTag({
        tagName: this.tags[tagUid],
        currentSelection: this.tags[this.activeTagUid]
      })
      localStorage.setItem('inspirationTagUid', tagUid)
      this.activeTagUid = tagUid

      this.animateTagChange()
    },
    animateTagChange() {
      const contentBlock = this.$refs.content
      this.contentHeight = contentBlock.offsetHeight
      contentBlock.style.minHeight = `${this.contentHeight}px`

      this.loading = true
      const listener = () => {
        this.fetchInspirationData(this.activeTagUid).then(() => {
          setTimeout(() => {
            this.loading = false
            contentBlock.style.minHeight = '0'

            contentBlock.removeEventListener('transitionend', listener, false)
          }, this.delay) // Save some time to load all images
        })
      }

      contentBlock.addEventListener('transitionend', listener, { once: true })
    }
  }
}
</script>
